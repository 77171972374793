import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    redirect: '/activityAuth'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      keepAlive: true
    }
  },
  // {
  //   path: '/overview',
  //   name: 'overview',
  //   component: () => import(/* webpackChunkName: "overview" */ '../views/overview.vue'),
  //   meta: {
  //     keepAlive: true
  //   }
  // },
  {
    path: '/activityAuth',
    name: 'activityAuth',
    component: () => import(/* webpackChunkName: "overview" */ '../views/activityAuth.vue'),
    meta: {
      keepAlive: true
    }
  },
  // {
  //   path: '/demandList',
  //   name: 'demandList',
  //   component: () => import(/* webpackChunkName: "demandList" */ '../views/demandList.vue'),
  //   meta: {
  //     keepAlive: true
  //   }
  // },
  // {
  //   path: '/configNeedType',
  //   name: 'configNeedType',
  //   component: () => import(/* webpackChunkName: "configNeedType" */ '../views/configNeedType.vue'),
  //   meta: {
  //     keepAlive: false
  //   }
  // },
  {
    path: '*',
    name: 'all',
    redirect: '/login'
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path != '/login') {
    const token = localStorage.getItem('token')
    if (token) {
      next()
    } else {
      next({ path: '/login' });
    }
  } else {
    next()
  }
})


export default router
