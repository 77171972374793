import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import router from '../router/index'
import {BASE_URL} from "../utils/index.js";

const service = axios.create({
  // baseURL: 'http://info.gz2vip.91tunnel.com',
  baseURL: BASE_URL, 
  // baseURL: 'https://ict.zhiliandata.com',
  // baseURL: 'http://cgxfd3.natappfree.cc',
  timeout: 120000
});



service.interceptors.request.use(config => {
  if (config.url != '/info/userInfo/login') { // 登录接口调用不需要设置token
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
  }
  return config;
}, error => {
  Promise.reject(error);
})

const handlerError = (errorMessage) => {
  Message({
    message: errorMessage,
    type: 'error',
    duration: 3 * 1000
  });
  router.push({
    path: '/login'
  })
}

service.interceptors.response.use(
  response => {
    if (response.config.responseType == "blob") return response.data;
    if (response.data.code == 401 ||response.data.code == 102001 || response.data.code == 102002) {
      handlerError('没有权限或token过期，请重新登录!')
      return Promise.reject('没有权限或token过期，请重新登录!')
    } else if (response.data.code == 500 ) {
      Message({
        message: response.data.msg || '服务器异常!',
        type: 'error',
        duration: 3 * 1000
      });
      return Promise.reject(response.data.msg || '服务器异常!')
    } else if (response.data.code != 0) {
      Message({
        message: response.data.msg || '未知异常!',
        type: 'error',
        duration: 3 * 1000
      });
      return Promise.reject(response.data.msg || '未知异常!')
    } 
    return response.data;
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
