
import fetch from './fetch.js';

export function login(params) {
  return fetch({
      url: '/marketing/user/login',
      method: 'post',
      data: params,
      headers: { "Content-Type": "application/json" }
  });
}

export function getDynamicTablePage(params) {
  return fetch({
    url: '/marketing/dynamicTableInfo/getPage',
    method: 'get',
    params
  });
}

export function updateDynamicTable(params) {
  return fetch({
      url: '/marketing/dynamicTableInfo/add',
      method: 'post',
      data: params,
      headers: { "Content-Type": "application/json" }
  });
}

export function getDynamicTable(id) {
  return fetch({
      url: '/marketing/dynamicTableInfo/get?id=' + id,
      method: 'get'
  });
}

export function delDynamicTable(id) {
  return fetch({
      url: '/marketing/dynamicTableInfo/delete?id=' + id,
      method: 'get',
  });

}
export function uploadFile(params) {
    return fetch({
        url: '/marketing/file/add',
        method: 'post',
        data: params,
        headers: { "Content-Type": "multipart/form-data" }
    });
}